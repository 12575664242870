import {
  BRANDS,
  ENVIRONMENTS,
  SearchPanelConfig,
  assetPath,
  currentBrand,
  currentEnvironment,
  isTravelPlusHomepage,
} from './ssr-helper';
import { getHostName } from './basepaths';
import { Logger } from './services/logging';

export enum BRAND_NAMES {
  VAA = 'Virgin Atlantic',
  VHOLS = 'Virgin Atlantic Holidays',
}

type PreloadFile = {
  name: string;
  path: string;
  type: string;
  ext: string;
};

export interface PageHeader {
  title: string;
  description: string | null;
  canonicalUrl: string;
  brandName: string;
  favicon: string;
  ensightenbootstrap?: string;
  preloadFiles?: PreloadFile[];
  jQuery?: string;
  faviconAppleTouch: string;
  favicon16: string;
  favicon32: string;
  faviconSafariPinned: string;
  appleTouchIconPrecomposed: string;
  searchPanelConfig?: SearchPanelConfig;
  isHomepage?: boolean;
  localeInfo?: string;
  aemLastModifiedDate?: string;
  regenerateTime?: string;
}

const getEnsightenBootstrapBasepath = () => {
  switch (currentEnvironment) {
    case ENVIRONMENTS.TEST1:
    case ENVIRONMENTS.TEST2:
    case ENVIRONMENTS.TEST3:
    case ENVIRONMENTS.TEST4:
    case ENVIRONMENTS.TEST5:
    case ENVIRONMENTS.UAT:
    case ENVIRONMENTS.LOCAL:
      switch (currentBrand) {
        case BRANDS.VAA:
          return isTravelPlusHomepage
            ? 'https://tms.virginatlantic.com/vaa/kiosk-staging/Bootstrap.js'
            : 'https://tms.virginatlantic.com/vaa/ue_bw_stg/Bootstrap.js';
        case BRANDS.VHOLS:
          return 'https://nexus.virginholidays.co.uk/vaa/vhols-staging/Bootstrap.js';
        default:
          break;
      }
      break;
    case ENVIRONMENTS.PROD:
      switch (currentBrand) {
        case BRANDS.VAA:
          return isTravelPlusHomepage
            ? 'https://tms.virginatlantic.com/vaa/kiosk/Bootstrap.js'
            : 'https://tms.virginatlantic.com/vaa/ue_bw/Bootstrap.js';
        case BRANDS.VHOLS:
          return 'https://nexus.virginholidays.co.uk/vaa/vhols/Bootstrap.js';
        default:
          break;
      }
      break;
    default:
      break;
  }
};

export function getErrorPageHeader(): PageHeader {
  return {
    // eslint-disable-next-line quotes
    title: "Something's gone wrong",
    brandName: '',
    description: 'We`re working to fix this as soon as possible.',
    canonicalUrl: '',
    favicon: `${assetPath}/_nextfavicons/favicon.ico`,
    faviconAppleTouch: `${assetPath}/_nextfavicons/apple-touch-icon.png`,
    favicon16: `${assetPath}/_nextfavicons/favicon-16x16.png`,
    favicon32: `${assetPath}/_nextfavicons/favicon-32x32.png`,
    faviconSafariPinned: `${assetPath}/_nextfavicons/safari-pinned-tab.svg`,
    appleTouchIconPrecomposed: `${assetPath}/_nextfavicons/apple-touch-icon-precomposed.png`,
    ensightenbootstrap: getEnsightenBootstrapBasepath(),
    jQuery: `${assetPath}/_nextscripts/jquery.min.js`,
  };
}

export async function getPageHeader(): Promise<PageHeader> {
  return {
    // eslint-disable-next-line quotes
    title: "Something's gone wrong",
    brandName: '',
    description: 'We`re working to fix this as soon as possible.',
    canonicalUrl: '',
    favicon: `${assetPath}/_nextfavicons/favicon.ico`,
    faviconAppleTouch: `${assetPath}/_nextfavicons/apple-touch-icon.png`,
    favicon16: `${assetPath}/_nextfavicons/favicon-16x16.png`,
    favicon32: `${assetPath}/_nextfavicons/favicon-32x32.png`,
    faviconSafariPinned: `${assetPath}/_nextfavicons/safari-pinned-tab.svg`,
    appleTouchIconPrecomposed: `${assetPath}/_nextfavicons/apple-touch-icon-precomposed.png`,
    ensightenbootstrap: getEnsightenBootstrapBasepath(),
    preloadFiles: await getPreloadFiles(),
    jQuery: `${assetPath}/_nextscripts/jquery.min.js`,
  };
}

export async function getPageHeaderData(
  pageTitle: string,
  description: string,
  canonicalUrl: string,
  searchPanelConfig: SearchPanelConfig,
  lastModifiedDate?: string
): Promise<PageHeader> {
  const pageHeader = await getPageHeader();

  pageHeader.title = pageTitle
    ? pageTitle
    : currentBrand === BRANDS.VAA
    ? BRAND_NAMES.VAA
    : BRAND_NAMES.VHOLS;
  pageHeader.brandName =
    currentBrand === BRANDS.VAA ? BRAND_NAMES.VAA : BRAND_NAMES.VHOLS;
  pageHeader.description = description ?? '';
  pageHeader.canonicalUrl = canonicalUrl ?? '';
  pageHeader.searchPanelConfig = searchPanelConfig;
  pageHeader.aemLastModifiedDate = lastModifiedDate ?? '';

  return pageHeader;
}

export const getPreloadFiles = async (): Promise<PreloadFile[]> => {
  const hostName = getHostName();
  try {
    const buildEndpoint = `${getHostName(true)}/next-header-footer/api/build`;
    const res = await fetch(buildEndpoint, { method: 'GET' });

    if (!res.ok) {
      // new Logger().error('Failed to fetch build info from: ' + buildEndpoint);
      return [];
    }

    const buildJson = await res.json();
    const assets = buildJson['buildInfo']['assets'] || {};
    const cssFiles = assets['css'] || [];
    const jsFiles = assets['js'] || [];
    const fontFiles = assets['fonts'] || [];

    const files = [cssFiles, jsFiles, fontFiles].flat();

    if (files.length > 0) {
      return files.map((file) => {
        return {
          ...file,
          path: `${hostName}${file.path}`,
        };
      });
    }
    return [];
  } catch (error) {
    new Logger().error('Error while fetching preload files: ' + error);
    return [];
  }
};
